import * as React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import TitleBlock from "../components/titleBlockPages"

const TandC = ({ data }) => {
  ///page details
  const pageDataEdges = data.allPagetermsJson.edges[0].node;
  return (
    <Layout>
      <Seo
        title="Terms And Conditions"
        description="Read the Tkings Terms And Conditions >>"
      />
      <TitleBlock
        pageTitle={pageDataEdges.pageTitle}
        pageSubTitle={pageDataEdges.pageSubTitle}
        pageTitleBody={pageDataEdges.pageTitleBody}
        img={pageDataEdges.pageTitleImg}
      />
      <div className="container">
        <div className="row">
          <div className="col my-5">
              <p><strong>Standard Terms and Conditions and General Trading Information</strong></p>
              <ol>
              <li>
                <strong> These terms and conditions apply to all orders and supersedes all others.</strong>
                <p>Receipt of acknowledgement of order by you, constitutes your acceptance that our conditions are the only conditions that apply to the contract notwithstanding any purported terms put forward by you.</p>
              </li>
              <li>
                <strong> Payment Terms</strong>
                <p>All goods will be supplied against a proforma invoice unless a credit account has been opened after acceptance of bankers’ reference and two (2) trade references. Payment is to be made within 30 days from the date of the invoice. We understand and may exercise our statutory right of interest under the Late Payment of Commercial Debts (Interest) Act 1998 if we are not paid according to our credit terms.</p>
                <ol>
                  <li>Any discounts are offered on the strict understanding that accounts are paid by the due date. We reserve the right to invoice any such discounts to accounts which become overdue.</li>
                  <li>Passing of Title and Risk</li>
                  <li>The risk in the goods shall pass to you on delivery.</li>
                  <li>All goods delivered or not, remain our property until payment is received in full.</li>
                  <li>Until such time as payment in full is made you shall retain such goods separately from other goods and clearly mark them in such a way that they can be readily identified as being our property and any payment received by you for any sale of such goods must be held in a separate account in trust for us. In the event of non-payment by you for such goods we will, without loss of any rights or remedy, remove from your possession those goods belonging to us in accordance with these conditions and we shall be entitled to enter upon the property where the goods are stored and repossess and remove the same. You hereby grant us irrevocable license to enter your premises for the said purposes.</li>
                </ol>
              </li>
              <li>
                <strong> Products</strong>
                <p>We reserve the right to alter any details or design of products illustrated without notice and while every effort is made to describe goods accurately in the catalogue no warranty is given as to accuracy and no responsibility will be accepted for error or mis-description and any resulting loss.</p></li>
              <li>
                <strong> Quotations</strong>
                <p>Orders are accepted subject to our right to adjust prices quoted to take account of any changes in the law or Government regulations requiring us to increase prices by way of direct taxation, import duties, customs and excise duties or otherwise. The prices are based on today&rsquo;s current costs of production and in the event of any increase in wages or costs of materials to us occurring after the confirmation or accepted contract, we shall be entitled to charge such increases to you.</p></li>
              <li>
                <strong> Prices</strong>
                <p>Where applicable all prices quoted are subject to V.A.T. at the current rate.</p></li>
              <li>
                <strong> Delivery</strong>
                <ol>
                  <li>Every effort will be made to deliver on time, but any delivery date specified is a best estimate and no liability is accepted for any loss arising from delay or error in delivery of the goods. All deliveries will be charged at the prevailing rates applying at the date of such delivery.</li>
                  <li>Special rush deliveries can usually be arranged but will usually be subject to additional charges (e.g. rush print charges and rush delivery charges) which will be charged to you at current commercial rates.</li>
                </ol>
              </li>
              <li>
                <strong> Quantity Variation</strong>
                <p>We shall be deemed to have fulfilled our contract by delivery of a quantity within 10% plus or minus of the quantity of printed goods ordered and you will be charged at the contract rate for the quantity delivered.</p></li>
              <li>
                <strong> Claims</strong>
                <ol>
                  <li>Claims arising from damages, delay or partial loss in transit must be made in writing to us, so as to reach us within 5 days of delivery.</li>
                  <li>All claims with regard to the quality or quantity of the goods shall be made in writing to us so as to reach us within 5 days of receipt of goods or such goods shall be deemed to comply as to quality and quantity with the terms of the contract.</li>
                  <li>You must examine all goods delivered at the time of delivery. We shall not be liable for any loss arising from damage caused to the goods in transit unless loss or damage is noted on the delivery note at time of delivery.</li>
                  <li>Claims in respect of non-delivery must be made in writing so as to reach us within 4 days from receipt of our invoice.</li>
                </ol>
              </li>

              <li>
                <strong> Liability</strong>
                <ol>
                  <li>Save in so far as defects in the goods cause death, injury or damage to personal property, our liability for any loss or damage suffered by you in respect of the goods shall be limited to the contract value of the goods.</li>
                  <li>We can accept no responsibility for loss or damage arising from the supply of goods under this contract unless you have fully complied with the notification of claims procedure set out in clause 10.</li>
                  <li>Nothing in these terms and conditions shall affect the right of the consumer.</li>
                </ol>
              </li>
              
              <li>
                <strong> Cancellation Charges</strong>
                <p>A charge will be made on cancelled orders, together with a charge for all work carried out up to the date of written cancellation.</p>
              </li>
              <li>
                <strong> Samples</strong>
                <p>These will be submitted on approval and will be charged if not returned in good condition within 14 days.</p>
              </li>
              <li>
                <strong> Overdue Accounts</strong>
                <ol>
                  <li>No goods will be delivered on accounts which remain unpaid 14 days after payment is due. Interest will be charged on overdue accounts, at the rate of 5% above National Westminster Bank plc base rate from time to time from the date the account became due until payment is received. This does not prevent us from pursuing payment of overdue accounts at any time after payment becomes due and shall be in addition to and without prejudice to any other rights we may have against you.</li>
                  <li>We reserve the right to charge you for any legal or collection charges where it is necessary to obtain payment from you of an overdue account a third party or Court proceedings.</li>
                </ol>
              </li>
              <li>
                <strong> Quantity Changes to Orders</strong>
                <p>Any changes in quantity ordered must be made in writing to us prior to commencement of processing. Any increase in the order must be regarded as a separate contract unless written notification is received before work commences on the original order.</p>
              </li>
              <li>
                <strong>Artwork and Printing</strong>
                <ol>
                  <li>All artwork and print charges will be levied where necessary unless previously stipulated by us.</li>
                  <li>Where applicable the prices shown include printing one colour one position from camera ready artwork supplied, for additional positions or colours of printing please phone to obtain exact quotation. The standard printing colours are yellow, white, black, gold, silver, purple, brown, red, light green, medium green, dark green, light blue, medium blue, dark blue. We will match your own house colours as close as possible but where you specify non standard printing colours, there will be an additional special match charge.</li>
                </ol>
              </li>
              <li>
                <strong> Small Orders</strong>
                <p>Where you require a quantity smaller than the minimum quantity shown the price list, this is normally possible but usually carries a small order surcharge.</p>
              </li>
              <li>
               <strong> Force Majeure</strong>
                <p>We will not be held responsible for failure or delay in the carrying out of our obligations under the contract arising out of any cause outside our reasonable control or by inability to procure materials or articles except at higher prices due to any such cause and in such circumstances we shall be entitled by notice to terminate the contract in whole or in part without incurring any liability whatsoever to you.<br />PLEASE NOTE: The products in any parts of our marketing materials and brochures have not necessarily been supplied to or endorsed by the companies whose names and logos have been used. The printing of such is a guide to position and printing effect only. We apologise for any inconvenience caused</p>
              </li>
              </ol>
              <p><strong>Terms and Conditions of Sale</strong></p>
              <p>Our Contact details:</p>
              <p><strong>T King Associates Ltd<br /></strong>Unit 14 Swan Business Centre,<br />Buckingham,<br />MK18 1TB</p>
              <p>Telephone: +44 (0)1280 824 836</p>
              <p>Email:&nbsp;<a href="mailto:info@tkingassociates.com">info@tkingassociates.com</a></p>
              <p><strong>Location:</strong>&nbsp;<a href="https://www.tkingassociates.com/contact">View Map</a></p>
              <p><strong>Order Acceptance<br /></strong>When an e-mail confirmation of your order is received, this is to indicate that we have received your order &ndash; it is not an order acceptance. We have included this term to protect us in the case that a mistake has been made in pricing, we have inadvertently under-priced goods, or we are no longer able to supply a particular product for some reason.</p>
              <p><strong>Credit Card Security<br /></strong>We use SagePay for online payments and, therefore, never see your credit card details.</p>
              <p><strong>Delivery Time and Shipping Method<br /></strong>We&rsquo;ll despatch your order within 7 to 10 working days if we have the products you have selected in stock, we normally do.</p>
              <p>Purchases for delivery in the UK or mainland Europe are despatched by &lsquo;next day&rsquo; post or courier and will normally be delivered to you the day after despatch. For other destinations, times may vary. When your order is despatched we will advise by telephone or email.</p>
              <p><strong>Delivery Charges<br /></strong>Delivery charges will be calculated and quoted if applicable.</p>
              <p><strong>VAT, Taxes and Duties<br /></strong>VAT is charged on all orders for the UK.</p>
              <p>Some products (e.g. clothing in children&rsquo;s sizes) is VAT exempt or zero-rated.</p>
              <p>Taxes and/or Import duties may apply to goods delivered outside the UK. It is the buyers responsibility to determine if any taxes and/or duties are payable and to pay them.</p>
              <p><strong>Back Orders<br /></strong>If any of your ordered items are not in stock, we will back order for you. You will always be emailed or called with details to ensure that you are kept up to date with the progress of your order.</p>
              <p><strong>Privacy Policy<br /></strong>The Information We Need and How We Use It</p>
              <p>T King Associates, a trading name of T King Associates, does not disclose buyers&rsquo; information to third parties.</p>
              <p>When you order from us or make an enquiry, we will require your name, address, e-mail address, phone number and other similar information to enable us to process your request, notify you of acceptance of orders and deliver the goods ordered.</p>
              <p>We may, unless you tell us not to, pass this information to our couriers or other agents in case they need to contact you to arrange the processing or delivery of your order.</p>
              <p>As part of our efforts to ensure that you are aware of the latest developments and offers, we would like to use this information to advise you of new products, special offers or changes to our site. If you would like to receive this information, please indicate your preference when placing your order. You may &ldquo;un-subscribe&rdquo; at any time &ndash; just send an email to sales@tkingassociates.com. Please ensure that you notify us of your preference for each e-mail address that you may have given to us.</p>
              <p><strong>Cookies</strong></p>
              <p><strong>Session ID<br /></strong>This web site sets a single &ldquo;Session ID&rdquo; cookie, essential for the operation of the web site which is used to &lsquo;remember&rsquo; user specific information only during the time that a user is using the web site. This cookie is deleted when you close your browser. No personally identifiable information is stored in the cookie at any time. This is a &lsquo;Category 1&rsquo; cookie within European legislation.</p>
              <p><strong>Google Analytics<br /></strong>Google Analytics uses cookies to collect information about how visitors use our site. We use the information to compile reports and to help us improve the site. The cookies collect information in an anonymous form, including the number of visitors to the site, where visitors have come to the site from and the pages they visited. These are Category 2 cookies.</p>
              <p>For more information on the use of Google cookies visit www.google.com/policies.</p>
               <p><strong>Returns Policy<br /></strong>Your right to Cancel (Non-business customers only)</p>
              <p>If for any reason you are not satisfied with the product(s) you have bought from us, please contact us straight away. In any case, this must be within 7 days of receipt.</p>
              <p>Please also enclose a letter explaining why you wish to return the product(s) and we will refund you in full within 28 days of the receipt of your returned order.</p>
              <p>However, please note that unless faulty, items that have been personalised cannot be returned for refund or credit.</p>
              <p>For all returns, except where the item is faulty, you will be required to arrange and pay for the return of the products to us. For faulty items, the cost of returned postage will be refunded to you.</p>
              <p>To return any products to us, please:</p>
              <ol>
              <li>Make sure to tell us whether you want an Exchange or Refund.</li>
              <li>We recommend that you obtain proof of posting since we cannot be responsible for packages lost in transit.</li>
              <li>Pack the items safely, enclose the invoice and send it back to:<br />T King Associates Ltd<strong><br /></strong>Unit 14 Swan Business Centre,<br />Buckingham,<br />MK18 1TB</li>
              </ol>


              </div>
          </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query MyQuery {
    
    
    allPagetermsJson {
      edges {
        node {
          pageTitleBody
          pageTitle
          pageSubTitle
          pageTitleImg {
                childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
              }
        }
      }
    }
}`
export default TandC
